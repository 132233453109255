/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    ul: "ul",
    li: "li",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "day-4-camp-cleanup"
  }, "Day 4: Camp Cleanup"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://adventofcode.com/2022/day/4"
  }, "https://adventofcode.com/2022/day/4")), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: ", React.createElement(_components.a, {
    href: "https://github.com/NickyMeuleman/scrapyard/blob/main/advent_of_code/2022/src/day_04.rs"
  }, "my solution in\nRust"))), "\n", React.createElement(_components.p, null, "Today, the elves have to clean up their camp."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Each area of the camp has a unique ID number."), "\n", React.createElement(_components.li, null, "Each elf is assigned a range of those IDs to clean."), "\n"), "\n", React.createElement(_components.p, null, "The elves are split into groups of 2."), "\n", React.createElement(_components.p, null, "Your input is a big list of the section assignments for each pair.\nEach line of the input represents a pair of elves."), "\n", React.createElement(_components.p, null, "The two elves in a line are seperated by a comma ", React.createElement(_components.code, null, ","), "."), "\n", React.createElement(_components.p, null, "The start and end (inclusive) of their assigned range is seperated by a dash ", React.createElement(_components.code, null, "-"), "."), "\n", React.createElement(_components.p, null, "An example input looks like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt",
    title: "input.txt"
  }, "2-4,6-8\n2-3,4-5\n5-7,7-9\n2-8,3-7\n6-6,4-6\n2-6,4-8\n")), "\n", React.createElement(_components.h2, {
    id: "part-1"
  }, "Part 1"), "\n", React.createElement(_components.p, null, "The question asks to find in how many pairs one range fully contains the other."), "\n", React.createElement(_components.p, null, "I looped through every line of the input, only keps the ones with a full overlap, and counted how many were left."), "\n", React.createElement(_components.p, null, "I started off with this pseudocode that I then filled in:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "pseudocode.rs"
  }, "input\n    .lines()\n    .filter()\n    .count()\n")), "\n", React.createElement(_components.p, null, "A range completely overlaps an other range if:"), "\n", React.createElement(_components.p, null, "Its minimum is lower (or equal) than the other minimum and its maximum is higher (or equal) than the other maximum."), "\n", React.createElement(_components.p, null, "I did this check twice, once for each elf."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Once to check if elf1 completely overlaps with elf2"), "\n", React.createElement(_components.li, null, "Once to check if elf2 completely overlaps with elf1."), "\n"), "\n", React.createElement(_components.p, null, "The longest part of this solution is parsing those strings into 4 numbers: ", React.createElement(_components.code, null, "min1"), ", ", React.createElement(_components.code, null, "max1"), ", ", React.createElement(_components.code, null, "min2"), ", and ", React.createElement(_components.code, null, "max2"), "."), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "The solution includes a commented out alternative way to check for a complete overlap")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_04.rs"
  }, "pub fn part_1() -> usize {\n    let input = std::fs::read_to_string(\"src/day04.txt\").unwrap();\n\n    input\n        .lines()\n        .filter(|line| {\n            let (elf1, elf2) = line.split_once(',').unwrap();\n            let (min1, max1) = elf1.split_once('-').unwrap();\n            let (min2, max2) = elf2.split_once('-').unwrap();\n            let min1: u32 = min1.parse().unwrap();\n            let max1: u32 = max1.parse().unwrap();\n            let min2: u32 = min2.parse().unwrap();\n            let max2: u32 = max2.parse().unwrap();\n\n            // (min1 >= min2 && max1 <= max2) || (min2 >= min1 && max2 <= max1)\n            // equivalent:\n            (min1 <= min2 && max1 >= max2) || (min2 <= min1 && max2 >= max1)\n        })\n        .count()\n}\n")), "\n", React.createElement(_components.h2, {
    id: "part-2"
  }, "Part 2"), "\n", React.createElement(_components.p, null, "The question asks to find in how many pairs one range overlaps the other (partially, or completely)."), "\n", React.createElement(_components.p, null, "The overall setup and the number parsing piece are identical to part1.\nThe only change is the line that determines if a line is kept or not."), "\n", React.createElement(_components.p, null, "A range overlaps an other when:"), "\n", React.createElement(_components.p, null, "the biggest minimum is smaller (or equal) than the smallest maximum."), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "The solution includes a commented out alternative way to check for an overlap")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_04.rs"
  }, "pub fn part_2() -> usize {\n    let input = std::fs::read_to_string(\"src/day04.txt\").unwrap();\n\n    input\n        .lines()\n        .filter(|line| {\n            let (elf1, elf2) = line.split_once(',').unwrap();\n            let (min1, max1) = elf1.split_once('-').unwrap();\n            let (min2, max2) = elf2.split_once('-').unwrap();\n            let min1: u32 = min1.parse().unwrap();\n            let max1: u32 = max1.parse().unwrap();\n            let min2: u32 = min2.parse().unwrap();\n            let max2: u32 = max2.parse().unwrap();\n\n            min1 <= max2 && max1 >= min2\n            // equivalent:\n            // min1.max(min2) <= max1.min(max2)\n        })\n        .count()\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
